:root {
    --dark: #1D252A;
    --light: #FFFFFF;
    --accent: #FE6E2F;
    --accent-lighten: rgba(254, 110, 47, 0.5);
    --secondary: rgb(117, 145, 184);
    --secondary-lighten: rgba(117, 145, 184, 0.1);
    --grey: #666E74;
    --grey-lighten: rgba(204, 204, 204, 0.3);
    font-family: 'Rubik', sans-serif;
}
body {
    background-color: var(--dark);
    color: var(--light);
}
.loader {
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    animation: roll 1s ease-in-out infinite alternate;
}
.loader:after{
    content:"";
    position: absolute;
    inset: 5px;
    border-radius: 50%;
    border: 5px solid ;
    border-color: #ff3d00 transparent;
}
@keyframes roll {
    0% {
        transform: translateX(-150%) rotate(0deg) ;
    }
    100% {
        transform:  translateX(150%) rotate(360deg);
    }
}
