.navbar {
    margin: 0 auto;
    width: 80%;
    max-width: 1000px;
}
.navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
    color: red;
    font-family: 'Kdam Thmor Pro', sans-serif;
}
.navbar a {
    text-decoration: none;
    color: var(--grey);
}
.navbar a.active {
    color: var(--accent);
}
.navbar a:not(.active, :last-child):hover {
    text-decoration: none;
    color: var(--accent-lighten)
}
.navbar a li.create-room {
    border-radius: 10px;
    padding: 5px 20px;
    transform: translateY(-5px);
    background: var(--accent);
    color: var(--dark);
}
.navbar a li.create-room:hover {
    background: var(--secondary);
}
